import * as _ from "lodash";
import { runPipelines } from "../../Services/pipelines/pipeline.slice";

export const getKey = (templateid, indexarr)=>{
    if(indexarr==undefined){
        return templateid;
    }else{
        let indexarrcopy = JSON.parse(JSON.stringify(indexarr));
        if(indexarrcopy?.length>0){
            let suffix = indexarrcopy.join("__");
            return templateid+"__"+suffix;
        }else{
            return templateid;
        }
    }
}



export const camelCaseHypens = (name)=>{
    let parts = name.split("_");
    let res = parts[0];
    for(let i=1; i< parts.length; i++){
        let part = parts[i];
        let firstletter = part.splice(0,1);
        part = firstletter.toUpperCase()+part;
        res = res+part
    }
    return res;
}

export const generateStyleValue = (style)=>{
    let styletype= style.type;
    let res = "";
    if(styletype=="number"){
        if(style.value!=undefined){
            if(style.unit!=""&&style.unit!=undefined){
                res = style.value.toString()+style.unit;
            }else{
                res = parseFloat(style.value);
            }
            
        }
    }else{
        res = style.value;
    }
    return res;
}

export const generateStyleSheet = ()=>{
    const styles = StyleSheet.create({});
    return styles;
}

/**
 * 
 * @param {*} word 
 * @param {*} phrase
 * 
 */

const matcher = (word, phrase)=>{
    let matchscore = 100;
    let matchstartindex = 0;
    let matchfound = false;
    while((word.length-matchstartindex)>=phrase.length){
        let subword = word.slice(matchstartindex, word.length);
        if(subword.startsWith(phrase)){
            matchfound = true;
            break;
        }else{
            matchstartindex = matchstartindex+1;
        }
    }
    if(matchfound){
        if(matchstartindex>0){
            matchscore = matchscore/matchstartindex
        }
        //normalize the matchscore for length
        if((word.length-phrase.length)>0){
            matchscore = matchscore/(word.length-phrase.length)   
        }
        return matchscore;
    }else{
        return 0;
    }
    
}

export const filterValues = (options, query)=>{
    let filteredoptions = [];
    for(let i=0; i < options.length; i++){
        let res = matcher(options[i], query);
        if(res>0){
            filteredoptions.push({
                "score": res,
                "option": options[i]
            })
        }
    }
    let sortedoptions = _.sortBy(filteredoptions, (val)=>{return -1*val.score});
    let resp = [];
    for(let i=0; i<sortedoptions.length; i++){
        resp.push(sortedoptions[i].option);
    }
    return resp;
}

export const getInteracionRunner = (interactions, dispatch, event)=>{
    return ()=>{
        for(let i=0; i<interactions.length; i++){
            dispatch(runPipelines({...interactions[i],
                                    event: event,
                                    source: "appevents"
                                  }))
        }
    }
    
}