import { toilerUrl } from "../env";

export const templatePipelineProdApi = async(payload)=>{
    let res = await fetch(toilerUrl+"pipelinebytemplateprod/"+payload,{
        "method": "GET"
    });
    return res.json();
}

export const getTemplateVariablesProdApi = async(payload)=>{
    let res = await fetch(toilerUrl+"variables/prodtemplates/"+payload,{
        "method": "GET"
    });
    return res.json();
}

export const getElementsProdApi = async(payload)=>{
    let res = await fetch(toilerUrl+"templatesprod/"+payload,{
        "method": "GET"
    });
    return res.json();
}

export const getTemplateSettingsApi = async(payload)=>{
    let res = await fetch(toilerUrl+ "templates/app/settings/"+payload,{
        "method": "GET"
    });
    return res.json();
}

export const getAppTemplatesApi = async(payload)=>{
    let res = await fetch(toilerUrl+"variables/gettemplates/html/"+payload,{
        "method": "GET"
    });
    return res.json();
}

export const getTemplateRootApi = async(payload)=>{
    let res = await fetch(toilerUrl+"getroot/"+payload,{
                "method": "GET"
              })
    return res.json();
}

export const getTemplateCssClassesProdApi = async(payload)=>{
    let res = await fetch(toilerUrl+"cssclasses/prodtemplates/"+payload,{
        "method": "GET"
    });
    return res.json();
}