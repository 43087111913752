import { useParams } from "react-router-dom";
import AppContainer from "../AppContainer";
import HtmlNativeElements from "../elements/HtmlNativeElements";
import ToilerElement from "../elements/ToilerElement";

const SingleBlock = ({
                        element, 
                        elmkey, 
                        indexarr,
                        position,
                        type,
                        childs,
                        parentkey,
                        parentposition,
                        parentindexarr,
                        templateinteractions,
                        path
                    })=>{
    const getClassName = ()=>{
        if(element.desktop&&element.mobile){
            return "";
        }else if(element.desktop&&element.mobile==false){
            return "only-desktop";
        }else if(element.mobile&&element.desktop==false){
            return "only-mobile";
        }else{
            return "display-none";
        }
    }
        
    return (
        <>
            {type=="html"&&
                <>
                    {element.elemtype=="native"&&
                        <HtmlNativeElements
                            element={element}
                            elmkey={elmkey}
                            indexarr={indexarr}
                            position={position}
                            type={type}    
                            childs={childs}
                            parentkey={parentkey}  
                            parentposition={parentposition}
                            parentindexarr={parentindexarr}
                            templateinteractions={templateinteractions}
                            path={path}                 
                        >
                        </HtmlNativeElements>
                    }
                    {element.elemtype=="toiler"&&
                        <ToilerElement
                            element={element}
                            elmkey={elmkey}
                            indexarr={indexarr}
                            position={position}
                            type={type}
                            parentkey={parentkey}
                        >
                        </ToilerElement>

                    }
                    {element.elemtype=="template"&&
                        <div className={getClassName()}>
                            <AppContainer
                                templateid={element._id}
                                indexarr={indexarr}
                                indexkey={element._id+""+element._id}
                                elmkey={elmkey}
                                position={position}
                                type={type}
                                childs={element.childs}
                                templateinteractions={element.interactions}
                                path={path}
                            >
                            </AppContainer>
                        </div>
                        
                    }
                </>
            }
        </>
    )
}

export default SingleBlock;