import { createSlice, createAsyncThunk, createSelector } from "@reduxjs/toolkit";
import { 
         getAppTemplatesApi, 
         getElementsApi, 
         getElementsProdApi,  
         getTemplateVariablesProdApi, 
         getTemplateCssClassesProdApi,
         templatePipelineProdApi,
         logRouteChangeApi,
         getTemplateRootApi
       } from "./apptemplate.service";
import { showError } from "../error.slice";
import {getKey} from "../../apps/appdesigner/utils";
import {generateSchema} from "../../Services/pipelines/server/utils";
import {executeElementConditions} from "../pipelines/server/server-apps/conditional";
import {TypeProps} from "./../../apps/appdesigner/Settings";
import { camelCaseHypens, generateStyleValue} from "../../apps/appdesigner/utils";
import { runPipelines } from "../pipelines/pipeline.slice";
import * as _ from "lodash";
import {interfaceUrl} from "../env";

const parseSchemaKey = (val)=>{
    if(val!=""){
        let steps = val.split(".");
        let appstep = steps[0];
        let appstepsplit = appstep.split("[");
        appstepsplit = appstepsplit[0];
        // appstepsplit = appstepsplit.split("]");
        // let appindex = appstepsplit[0];
        steps[0] = appstepsplit;
        return steps;
    }else{
        return [null, null, null];
    }
    
}

const getValuedSchema = (sc, sm, templatevariables)=>{
    for(let i=0; i< sc.length; i++){
        let val;
        if(sc[i].type=="object"){
            getValuedSchema(sc[i].subschema, sm[i].mapping, templatevariables);
            val = getAppObjectVal(
                                    sc[i].subschema, 
                                    sm[i].mapping,
                                    templatevariables,
                                    [],
                                    [],
                                    [],
                                    {},

                                 );
        }else{
            val = getAppObjectVal(
                                    sc[i], 
                                    sm[i],
                                    templatevariables,
                                    [],
                                    [],
                                    [],
                                    {});
        }
        sc[i] = {...sc[i],
                 value: val
                }
    }
}

export const getLocalStorageSchema = ()=>{
    let schema = [{
        "key": "localstorage",
        "label": "LocalStorage",
        "type": "object",
        "subschema":[],
        "value":{}
    }];
    let subschema = [];
    let lStorage = {};
    for(let i=0; i< localStorage.length; i++){
        let storagekey = localStorage.key(i);
        let storagekeyvalue = localStorage.getItem(storagekey);
        let keyschema = {
            "key": storagekey,
            "label": storagekey,
            "type": "string",
            "value": storagekeyvalue,
            "subschema":[]
        }
        subschema.push(keyschema);
        lStorage[storagekey] = storagekeyvalue
    }
    if(subschema.length>0){
        schema[0] = {...schema[0],
                     subschema: subschema,
                     value: lStorage
                    }
        return schema;
    }else{
        return []
    }
}

export const getSessionStorage = ()=>{
    let schema = [{
        "key": "localstorage",
        "label": "LocalStorage",
        "type": "object",
        "subschema":[],
        "value":{}
    }];
    let subschema = [];
    let lStorage = {};
    for(let i=0; i< localStorage.length; i++){
        let storagekey = localStorage.key(i);
        let storagekeyvalue = localStorage.getItem(storagekey);
        let keyschema = {
            "key": storagekey,
            "label": storagekey,
            "type": "string",
            "value": storagekeyvalue,
            "subschema":[]
        }
        subschema.push(keyschema);
        lStorage[storagekey] = storagekeyvalue
    }
    if(subschema.length>0){
        schema[0] = {...schema[0],
                     subschema: subschema,
                     value: lStorage
                    }
        return schema;
    }else{
        return []
    }   
}

const getValfromPipe = (
                            mapping, 
                            variables, 
                            loopvariables, 
                            inputs, 
                            routeparams,
                            type
                        )=>{
    let parsedVal = parseSchemaKey(mapping);
    let value;
    try{
        for(let i=0; i < parsedVal.length; i++){
            if(i==0){
                if(parsedVal[0]=="template"){
                    value = variables;
                }else if(parsedVal[0]=="loopvar"){
                    value = loopvariables;
                }else if(parsedVal[0]=="inputs"){
                    value = inputs;
                    i=i+1;
                }else if(parsedVal[0]=="routeparams"){
                    value = routeparams;
                }else if(parsedVal[0]=="localstorage"){
                    value = getLocalStorageSchema();
                }else if(parsedVal[0]=="sessionstorage"){
                    value = getSessionStorage();
                }
                
            }else if(i==(parsedVal.length-1)){
                let concernedkeyindex = _.findIndex(value, (k)=>{return k.key==parsedVal[i]});
                if(value[concernedkeyindex].deleted){
                    value = undefined;
                }else{
                    if(type=="file"){
                        let filesubschema = value[concernedkeyindex].subschema;
                        let urlindex = _.findIndex(filesubschema, (fss)=>{return fss.key=="url"});
                        value = filesubschema[urlindex].value;
                    }else{
                        value = value[concernedkeyindex].value;
                    }
                }
            }
            else{
                let concernedkeyindex = _.findIndex(value, (k)=>{return k.key==parsedVal[i]});
                if(value[concernedkeyindex]?.deleted){
                    value = undefined;
                    break;
                }else{
                    value = value[concernedkeyindex].subschema;
                }
            }
        }
    }catch(error){
        value = undefined
    }
    return value;
}
  
const isValidURL = (str)=> {
    if(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(str)) {
         return true
     } else {
         return false
     }
}

export const getAppObjectVal = (
                                sc, 
                                sm,
                                templatevariables, 
                                loopvariables, 
                                inputs,
                                routeparams, 
                                objectval,
                                env,
                                currentpath
                                )=>{
    if((sm!=undefined&&sm!=""&&sm!=null)){
        if(Array.prototype.isPrototypeOf(sm)){
            let schemamapping = [...sm];
            let schemaCopy = [...sc];
            for(let i=0; i<schemamapping.length;i++){
                    if(Array.prototype.isPrototypeOf(schemamapping[i]?.mapping)){ 
                        let subobjectval = getAppObjectVal(
                                                            sc[i].subschema,
                                                            schemamapping[i]?.mapping, 
                                                            templatevariables, 
                                                            loopvariables, 
                                                            inputs, 
                                                            routeparams,
                                                            {},
                                                            env,
                                                            currentpath
                                                           );

                        objectval[schemamapping[i].key] = subobjectval;
                    }else{
                        let sci = schemaCopy[i];
                        if(sci.type=="array"){
                            if(schemamapping[i].mapping?.action==undefined){
                                objectval[schemamapping[i].key] = "";
                            }else if(schemamapping[i].mapping?.action=="const"){
                                if(sci.subschema[0].type!="object"){
                                    let submapping = schemamapping[i].mapping?.mapping;
                                    let arrayval = [];
                                    for(let i=0; i< submapping.length; i++){
                                        if(submapping[i].action=="const"){
                                            arrayval.push(submapping[i].val)
                                        }else if(submapping[i].action=="get"){
                                            arrayval.push(getValfromPipe(
                                                                            submapping[i].val, 
                                                                            templatevariables, 
                                                                            loopvariables,
                                                                            inputs,
                                                                            routeparams,
                                                                            sci.subschema[0].type                        
                                                                        ));
                                        }
                                    }
                                    objectval[schemamapping[i].key] = arrayval;
                                }else{
                                    let arrval = [];
                                    for(let j=0; j< schemamapping[i].mapping.mapping.length; j++){
                                        let objval = getAppObjectVal(
                                                                      sci.subschema[0].subschema,
                                                                      schemamapping[i].mapping.mapping[j],
                                                                      templatevariables, 
                                                                      loopvariables, 
                                                                      inputs, 
                                                                      routeparams,
                                                                      {},
                                                                      env,
                                                                      currentpath
                                                                    );
                                        arrval.push(objval);
                                    }
                                    objectval[schemamapping[i].key] = arrval;
                                }
                            }else if(schemamapping[i].mapping?.action=="get"){
                                // console.log
                                objectval[schemamapping[i].key] = getValfromPipe(
                                                                                    schemamapping[i].mapping.val, 
                                                                                    templatevariables, 
                                                                                    loopvariables,
                                                                                    inputs,
                                                                                    routeparams,
                                                                                    sci.type
                                                                                );
                            }
                        }else if(sci.type=="file"){
                            if(schemamapping[i].mapping?.action==undefined){
                                objectval[schemamapping[i].key] = '';
                            }else if(schemamapping[i].mapping?.action=="const"){
                                objectval[schemamapping[i].key] = schemamapping[i].mapping?.file.url;
                            }else if(schemamapping[i].mapping?.action=="get"){
                                objectval[schemamapping[i].key] = getValfromPipe(
                                                                                  schemamapping[i].mapping?.val, 
                                                                                  templatevariables, 
                                                                                  loopvariables, 
                                                                                  inputs,
                                                                                  routeparams,
                                                                                  "file"
                                                                                );
                            }else if(schemamapping[i].mapping.action=="urlconst"){
                                objectval[schemamapping[i].key] = schemamapping[i].mapping.val;
                            }else if(schemamapping[i].mapping.action=="urlget"){
                                objectval[schemamapping[i].key] = getValfromPipe(
                                                                                  schemamapping[i].mapping.val,
                                                                                  templatevariables,
                                                                                  loopvariables,
                                                                                  inputs,
                                                                                  routeparams
                                                                                )
                            }

                        }
                        else{
                            if(schemamapping[i].mapping?.action==undefined){
                                objectval[schemamapping[i].key] = "";
                            }else if(schemamapping[i].mapping?.action=="const"){
                                if(schemamapping[i].key=="href"){
                                    let v = schemamapping[i].mapping?.val;
                                    let hostname = window.location.host;
                                    if(v!=undefined&&typeof v=="string"){
                                        if(env==false){
                                            if(!isValidURL(v)){
                                                if(v.startsWith("/")){
                                                    v = currentpath+v;
                                                }else{
                                                    v = currentpath+ "/"+v;
                                                }
                                            }
                                            objectval[schemamapping[i].key] = v;
                                        }else if(hostname==interfaceUrl){
                                            if(!isValidURL(v)){
                                                let pathname = window.location.pathname;
                                                let pathparts = pathname.split("/");
                                                if(v!=undefined&&typeof v=="string"){
                                                    if(v.startsWith("/")){
                                                        v = "/"+pathparts[1]+v;
                                                    }else{
                                                        v = "/"+pathparts[1]+"/"+v;
                                                    }
                                                }else{
                                                    v = "";
                                                }
                                                
                                            }
                                            objectval[schemamapping[i].key] = v;
                                        }else{  
                                            objectval[schemamapping[i].key] = v;
                                        }
                                    }else{
                                        objectval[schemamapping[i].key] = "";
                                    } 
                                }else{
                                    objectval[schemamapping[i].key] = schemamapping[i].mapping?.val;
                                }
                            }else if(schemamapping[i].mapping?.action=="get"){
                                // console.log
                                if(schemamapping[i].key=="href"){
                                    let hostname = window.location.host;
                                    let v = getValfromPipe(
                                        schemamapping[i].mapping?.val,
                                        templatevariables,
                                        loopvariables,
                                        inputs,
                                        routeparams,
                                        undefined
                                    )
                                    if(env==false){
                                        if(v!=undefined&&typeof v=="string"){
                                            if(!isValidURL(v)){
                                                if(v.startsWith("/")){
                                                    v = currentpath+v;
                                                }else{
                                                    v = currentpath+ "/"+v;
                                                }
                                            }
                                        }else{
                                            v = "";
                                        }
                                        
                                        objectval[schemamapping[i].key] = v;
                                    }else if(hostname==interfaceUrl){
                                        if(v!=undefined){
                                            if(!isValidURL(v)&&typeof v=="string"){
                                                let pathname = window.location.pathname;
                                                let pathparts = pathname.split("/");
                                                if(v.startsWith("/")){
                                                    v = "/"+pathparts[1]+v;
                                                }else{
                                                    v = "/"+pathparts[1]+"/"+v;
                                                }                                    
                                            }
                                        }else{
                                            v = "";
                                        }
                                        objectval[schemamapping[i].key] = v;
                                    }else{
                                        objectval[schemamapping[i].key] = v;
                                    }
                                }else{
                                    objectval[schemamapping[i].key] = getValfromPipe(
                                                                                        schemamapping[i].mapping?.val, 
                                                                                        templatevariables, 
                                                                                        loopvariables,
                                                                                        inputs,
                                                                                        routeparams,
                                                                                        undefined
                                                                                    );
                                }
                            }
                    }
                }
            }
            return objectval;
        }else{
          let val;
          if(sc.type=="array"){
            if(sm.mapping.action==undefined){
                 val = [];
            }else if(sm.mapping?.action=="const"){
                if(sc.subschema[0].type!="object"){
                    let submapping = sm.mapping?.mapping;
                    let arrayval = [];
                    for(let i=0; i< submapping.length; i++){
                        if(submapping[i].action=="const"){
                            arrayval.push(submapping[i].val)
                        }else if(submapping[i].action=="get"){
                            arrayval.push(getValfromPipe(
                                                            submapping[i].val, 
                                                            templatevariables, 
                                                            loopvariables,
                                                            inputs,
                                                            routeparams,
                                                            sc.type
                                                            ));
                        }
                    }
                    val = arrayval;
                }else{
                    let arrval = [];
                    let submapping = sm.mapping?.mapping;
                    for(let j=0; j< submapping.length; j++){
                        let val = getAppObjectVal(
                                                    sc.subschema[0].subschema, 
                                                    submapping[j],
                                                    templatevariables, 
                                                    loopvariables, 
                                                    inputs,
                                                    routeparams, 
                                                    {},
                                                    env,
                                                    currentpath
                                                    );
                        arrval.push(val);
                    }
                    val = arrval;
                }
            }else if(sm.mapping?.action=="get"){
                // console.log
                val = getValfromPipe(
                                        sm.mapping.val, 
                                        templatevariables, 
                                        loopvariables,
                                        inputs,
                                        routeparams,
                                        sc.type
                                    );
            }
          }else if(sc.type=="object"){
            if(sm.mapping?.action==undefined){
                objectval[sc.key] = getAppObjectVal(
                                                        sc.subschema, 
                                                        sm.mapping,
                                                        templatevariables, 
                                                        loopvariables, 
                                                        inputs,
                                                        routeparams, 
                                                        {},
                                                        env,
                                                        currentpath
                                                    ); 
            }else if(sm.mapping?.action=="get"){
                objectval[sc.key] = getValfromPipe(
                                                    sm.mapping.val, 
                                                    templatevariables, 
                                                    loopvariables,
                                                    inputs,
                                                    routeparams,
                                                    sc.type
                                                  );
            }
            return objectval;
          }else if(sc.type=="file"){
            if(sm.mapping?.action=="const"){
                val = sm.mapping.file.url;
            }else if(sm.mapping?.action=="get"){
                val = getValfromPipe(
                                        sm.mapping.val, 
                                        templatevariables, 
                                        loopvariables, 
                                        inputs,
                                        routeparams,
                                        "file")
            }else if(sm.mapping?.action=="urlconst"){
                val = sm.mapping.val;
            }else if(sm.mapping?.action=="urlget"){
                val = getValfromPipe(
                                        sm.mapping.val,
                                        templatevariables,
                                        loopvariables,
                                        inputs,
                                        routeparams
                                    )
            }
          }else{
            if(sc.key=="href"){
                if(sm.mapping?.action=="const"){ 
                    val = sm.mapping.val
                    let hostname = window.location.host;
                    // hack to make routing work in the app editor
                    if(env==false){
                        if(val!=undefined&&String.isPrototypeOf(val)){
                            if(!isValidURL(val)){
                                if(val.startsWith("/")){
                                    val = currentpath+val;
                                }else{
                                    val = currentpath+ "/"+val;
                                }
                            }
                        }else{
                            val = "";
                        }
                        
                    }else if(hostname==interfaceUrl){
                        if(val!=undefined&&String.isPrototypeOf(val)){
                            if(!isValidURL(val)){
                                let pathname = window.location.pathname;
                                let pathparts = pathname.split("/");
                                if(val.startWith("/")){
                                    val = "/"+pathparts[1]+val;
                                }else{
                                    val = "/"+pathparts[1]+"/"+val;
                                }                                    
                            }
                        }else{
                            val = "";
                        }
                    }
                }else if(sm.mapping?.action=="get"){
                    val = getValfromPipe(
                                            sm.mapping.val, 
                                            templatevariables, 
                                            loopvariables,
                                            inputs,
                                            routeparams,
                                            sc.type
                                            );
                    let hostname = window.location.host;
                    // hack to make routing work in the app editor
                    if(env==false){
                        if(val!=undefined&&String.isPrototypeOf(val)){
                            if(!isValidURL(val)){
                                if(val.startsWith("/")){
                                    val = currentpath+val;
                                }else{
                                    val = currentpath+ "/"+val;
                                }
                            }
                        }else{
                            val = "";
                        }
                        
                    }else if(hostname==interfaceUrl){
                        if(val!=undefined&&String.isPrototypeOf(val)){
                            if(!isValidURL(val)){
                                let pathname = window.location.pathname;
                                let pathparts = pathname.split("/");
                                if(val.startWith("/")){
                                    val = "/"+pathparts[1]+val;
                                }else{
                                    val = "/"+pathparts[1]+"/"+val;
                                }                                    

                            }
                        }else{
                            val = "";
                        }
                    }
                }
            }else{
                if(sm.mapping?.action=="const"){ 
                    val = sm.mapping.val
                }else if(sm.mapping?.action=="get"){
                    val = getValfromPipe(
                                            sm.mapping.val, 
                                            templatevariables, 
                                            loopvariables,
                                            inputs,
                                            routeparams,
                                            sc.type
                                            );
                }
            }
          }
          return val;  
        }
    }else{
        return {};
    }
}

const adddepstovar = (variable, depkey)=>{
    if(variable.depsarray==undefined){
        let depsarray = [];
        depsarray.push(depkey);
        variable = {...variable,
                    depsarray: depsarray
                    }
    }else{
        let depsarray = JSON.parse(JSON.stringify(variable.depsarray));
        let depexist = _.findIndex(depsarray,(dep)=>{return dep==depkey});
        if(depexist==-1){
            depsarray.push(depkey);
        }   
        variable = {...variable,
                    depsarray: depsarray
                    }
    }
    
    return variable;    
}

export const getInputProps = (type)=>{
    let typeprops = [...TypeProps];
    let textindex = _.findIndex(typeprops, (typeprop)=>{ return typeprop.type==type});
    let props = typeprops[textindex].props;
    return props;
}

const getInputOutputtype = (type)=>{
    let typeprops = [...TypeProps];
    let typeindex = _.findIndex(typeprops, (typeprop)=>{ return typeprop.type==type});
    let outputtype = typeprops[typeindex].outputtype;
    return outputtype;
}

const isElementInput = (element)=>{
    let inputtags = ["input", "select"];
    let tagindex = _.findIndex(inputtags, (tag)=>{ return element.tag==tag});
    if(tagindex>-1){
        return true;
    }else{
        return false;
    }
}

export const getAppPipelines = createAsyncThunk(
    "apptemplates/apppipeline",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        try{
            let apptemplates = getState().apptemplates;
            let key = payload.key;
            let templatepipelines = apptemplates.templatepipelines;
            if(templatepipelines[key]!=undefined){
                return {
                    templateid: payload.templateid,
                    key: payload.key,
                    pipelines: templatepipelines[key]
                };
            }else{
                let res = await templatePipelineProdApi(payload.templateid);
                return{
                    templateid: payload.templateid,
                    key: payload.key,
                    pipelines: res
                }
            }
        }catch(err){
            throw err;
        }
    }
)

export const getAppVariables = createAsyncThunk(
    "apptemplates/getappvariables",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        try{

            let resp = await getTemplateVariablesProdApi(payload.templateid);
            let res = {
                key: payload.key,
                variable: resp,
                elmkey: payload.elmkey,
                indexarr: payload.indexarr
            }
            return res;
            
            
        }catch(err){
            dispatch(showError("Error getting variables"));
            throw err;
        }
    }
)

export const getAppElements = createAsyncThunk(
    "apptemplates/getappelements",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        try{
            let apptemplates = getState().apptemplates;
            let appelements = apptemplates.appelements;
            let indexarr = payload.indexarr;
            let key = getKey(payload.templateid, indexarr);
            if(appelements[key]!=undefined){
                return {
                    source: payload.source,
                    elements: appelements[key],
                    key: key
                }
            }else{
                let resp = await getElementsProdApi(payload.templateid);
                let res = {
                    key: key,
                    elements: resp.elements,
                    source: payload.source
                }
                return res;
            }
        }catch(error){
            dispatch(showError("Error fetching elements"));
            throw error;
        }
    }
)

export const getAppTemplates = createAsyncThunk(
    "apptemplates/getapptemplates",
    async(payload, {dispatch, rejectWithValue})=>{
        try{

            let res = await getAppTemplatesApi(payload);
            return res;
        }catch(error){
            dispatch(showError("Error getting app templates"));
            throw error;
        }
    }
)

export const getAppclasses = createAsyncThunk(
    "apptemplates/getappclasses",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        try{
                let apptemplates = getState().apptemplates;
                let appclasses = apptemplates.appclasses;
                if(appclasses[payload.key]!=undefined){
                    if(appclasses[payload.key]!=null){
                        return {
                            key: payload.key,
                            classes: appclasses[payload.key],
                            elmkey: payload.elmkey,
                            indexarr: payload.indexarr
                        }
                    }else{
                        return null;
                    }
                }else{
                    let resp = await getTemplateCssClassesProdApi(payload.templateid);
                    if(resp!=null){
                        return {
                            key: payload.key,
                            classes: resp.result.classes,
                            elmkey: payload.elmkey,
                            indexarr: payload.indexarr
                        }
                    }else{
                        return null;
                    }
                }
        }catch(err){
            dispatch(showError("Error getting app classes"))
        }
    }
)

export const getTemplateRoot = createAsyncThunk(
    "apptemplates/gettemplateroot",
    async(payload, {dispatch, rejectWithValue})=>{
        try{
            let res = await getTemplateRootApi(payload);
            return {
                "templateid": payload,
                "result": res
            };
        }catch(error){
            dispatch(showError("Error getting template root"));
        }
    }
)

const updateVariableDeps = (
    appvariables, 
    keytoupdate,
    templatepipelines,
    dispatch
    )=>{
    let tempvar = appvariables[keytoupdate];    
    if(tempvar.depsarray!=undefined&&tempvar.depsarray.length>0){
        for(let i=0; i<tempvar.depsarray.length; i++){
            let childvar = appvariables[tempvar.depsarray[i]];
            if(childvar!=undefined){
                let sc = [...childvar.schema];
                let scm = [...childvar.schemamapping];
                getValuedSchema(sc, scm, tempvar.schema);
                appvariables[tempvar.depsarray[i]] = {
                                                        ...appvariables[tempvar.depsarray[i]],
                                                        schema: sc,
                                                        schemamapping: scm
                                                     }
                if(dispatch!=undefined){
                    getReactivePipelines(
                        tempvar.depsarray[i],
                        templatepipelines,
                        "variables",
                        dispatch
                    )
                }
                updateVariableDeps(
                                    appvariables, 
                                    tempvar.depsarray[i],
                                    templatepipelines,
                                    dispatch
                                );
            }
        }
    }
}

const getKeystobeUpdatedAtChange = (
    schemamapping, 
    schema,
    keystobeupdated
   )=>{
    for(let i=0; i < schemamapping.length; i++){
        if(Array.prototype.isPrototypeOf(schemamapping[i].mapping)){
            getKeystobeUpdatedAtChange(schemamapping[i].mapping, schema[i].subschema, keystobeupdated);
        }else{
            if(schemamapping[i].mapping.action=="get"){
                let val = schema[i].value;
                keystobeupdated.push({
                    "key": schemamapping[i].mapping.val,
                    "value": val
                })
            }
        }
    }
}

const updatekey = (key, val, variable, keystobeupdated)=>{
    if(key.startWith("template")){
        let keyparts = parseSchemaKey(key);
        let schema = variable.schema;
        let schemamapping = variable.schemamapping;
        for(let i=1; i< keyparts.length; i++){
            if(i==(keyparts.length-1)){
                let keyindex = _.findIndex(schema, (sc)=>{return sc.key==keyparts[i]});
                schema[keyindex] = {...schema[keyindex],
                                    value: val 
                                   }
                if(schemamapping[keyindex].action=="get"){
                    keystobeupdated.push({
                        key: keystobeupdated[keyindex].val,
                        value: val
                    })
                }
            }else{
                let keyindex = _.findIndex(schema, (sc)=> {return sc.key==keyparts[i]});
                if(keyindex>-1){
                    schema = schema[keyindex].subschema;
                    schemamapping = schemamapping[keyindex].mapping;
                }else{
                    throw new Error("unexpected key found");
                }
            }
        }
    }
}

const updatevariablekey = (
                            appvariables, 
                            parentkey,
                            keystobeupdated
                        )=>{
    let parentvar = appvariables[parentkey];
    let childkeystobeupdated = [];
    for(let i=0; i< keystobeupdated.length; i++){
        let key = keystobeupdated[i];
        updatekey(key.key, key.value, parentvar, childkeystobeupdated);
    }
    if(parentvar.parent!=""){
        updatevariablekey(appvariables, parentvar.parent, childkeystobeupdated);
    }
    updateVariableDeps(appvariables, parentkey);
}

// update variable and related variable in transformation pipelines
const updateVar = (
                    appvariables, 
                    childkey,
                    templatepipelines,
                    dispatch
                )=>{
    let childvar = appvariables[childkey];
    let pipelinestoRun = [];
    if(childvar.parent!=""){
        let keystobeupdated = [];
        getKeystobeUpdatedAtChange(
                                    childvar.schemamapping, 
                                    childvar.schema,
                                    keystobeupdated
                                  );
        updatevariablekey(
                            appvariables, 
                            childvar.parent, 
                            keystobeupdated,

                        );
        updateVariableDeps(
                            appvariables, 
                            childkey,
                            templatepipelines,
                            dispatch
                        );
    }else{
        updateVariableDeps(
                            appvariables, 
                            childkey,
                            templatepipelines,
                            dispatch
                        );
    }
}

const updateVariableMappingsOnPositionChanged = ()=>{

    
}

const addparenttovar = (variable, elmkey)=>{
    variable = {...variable,
                parent: elmkey
                }
    return variable;
}

const getElementPropsMapping = (appelements, parentkey, indexarr)=>{
    let tempelements = appelements[parentkey];
    let element;
    let secondPointer = tempelements;
    for(let i=0; i< indexarr.length; i++){
        if(i==(indexarr.length-1)){
            element = [...secondPointer[indexarr[i]].propsmapping];
        }else{
            secondPointer = secondPointer[indexarr[i]].childs;
        }
    }
    return element;
}

const loadAppInputs = (elements, inputs)=>{
    for(let i=0; i< elements.length; i++){
        if(isElementInput(elements[i])){
            let outputtype = getInputOutputtype(elements[i].inputtype);
            let inputvariable = {
                "type": outputtype,
                "value": "",
                "key": elements[i].name,
                "label": elements[i].name,
                "subschema":[]
            }
            let index = elements[i].inputindex;
            if(inputs.length<index){
                for(let j=(index-inputs.length); j<=index; j++){
                    inputs.push({
                        "type": "string",
                        "value": "",
                        "key": "",
                        "label": "",
                        "subschema":[]
                    })
                }
            }
            inputs[index] = inputvariable;
        }
        if(elements[i].childs!=undefined&&Array.prototype.isPrototypeOf(elements[i].childs)){
            loadAppInputs(elements[i].childs, inputs);
        }
    }
}

const checkInputForInputs = (
    schema,
    schemamapping
)=>{
    let found = false;
    for(let i=0; i< schemamapping.length; i++){
        if(schema[i].type=="object"){
            found = checkInputForInputs(schema[i].subschema, schemamapping[i].mapping)
        }else if(schema[i].type=="array"){
            if(schemamapping[i].mapping.action=="get"&&schemamapping[i].mapping.val.startsWith("inputs[template]")){
                found = true
            }else if(schemamapping[i].mapping.action=="const"){
                found = checkInputForInputs(schema[i].subschema, schemamapping[i].mapping.mapping);
            }
            found = checkInputForInputs(schema[i].subschema, schemamapping[i].mapping.mapping)
        }else{
            if(schemamapping[i].mapping.action=="get"&&schemamapping[i].mapping.val.startsWith("inputs[template]")){
                found = true
            }
        }
        if(found){
            break
        }
    }
    return found;
}

const checkInputForVariables = (
    schema,
    schemamapping
   )=>{
    let found = false;
    for(let i=0; i< schemamapping.length; i++){
        if(schema[i].type=="object"){
            found = checkInputForInputs(schema[i].subschema, schemamapping[i].mapping)
        }else if(schema[i].type=="array"){
            if(schemamapping[i].mapping.action=="get"&&schemamapping[i].mapping.val.startsWith("template[template]")){
                found = true
            }else if(schemamapping[i].mapping.action=="const"){
                found = checkInputForInputs(schema[i].subschema, schemamapping[i].mapping.mapping);
            }
            found = checkInputForInputs(schema[i].subschema, schemamapping[i].mapping.mapping)
        }else{
            if(schemamapping[i].mapping.action=="get"&&schemamapping[i].mapping.val.startsWith("template[template]")){
                found = true
            }
        }
        if(found){
            break
        }
    }
    return found;
}

const getReactivePipelines = (
    elmkey, 
    apppipelines,
    source,
    dispatch
)=>{

    let templatepipelines = apppipelines[elmkey];
    for(let i=0; i< templatepipelines.length; i++){
        if(templatepipelines[i].reactive==true){
            if(source=="inputs"){
                let schema = templatepipelines[i].pipeline[0].inputschema;
                let schemamapping = templatepipelines[i].pipeline[0].inputschemamapping;
                let found = checkInputForInputs(schema, schemamapping);
                if(found){
                    dispatch(runPipelines(
                                            {
                                                key: elmkey,
                                                pipelineid: templatepipelines[i]._id,
                                                schemamapping:[],
                                                position: [],
                                                source: "appslice"
                                            }
                    ))
                }
            }else if(source=="variables"){
                let schema = templatepipelines[i].pipeline[0].inputschema;
                let schemamapping = templatepipelines[i].pipeline[0].inputschemamapping;
                let found = checkInputForVariables(schema, schemamapping);
                if(found){
                    dispatch(runPipelines(
                                            {
                                                key: elmkey,
                                                pipelineid: templatepipelines[i]._id,
                                                schemamapping: [],
                                                position: [],
                                                source: "appslice"
                                            }
                            ))
                }
            }
        }
    }
}

const updateConstSchemamapping = (schema, schemamapping)=>{ 
    for(let i=0; i< schema.length; i++){
        if(schema[i].type=="object"){
            updateConstSchemamapping(schema[i].subschema, schemamapping[i].mapping);
        }else if(schema[i].type=="array"){
            if(schemamapping[i].mapping.action=="const"){
                if(schema[i].subschema[0].type!="object"){
                    let submapping = schemamapping[i].mapping.mapping;
                    for(let j=0; j< schema[i].value.length; j++){
                        submapping[j] = {...submapping[j],
                                         val: schema[i].value[j]
                                        }
                    }
                    let mapping = {...schemamapping[i].mapping};
                    mapping = {
                                ...mapping,
                                mapping: submapping
                              }
                    schemamapping[i] = {...schemamapping[i],
                                        mapping: mapping
                                        }
                }else{
                    let submapping = schemamapping[i].mapping.mapping;
                    for(let j=0; j< schema[i].value.length; j++){
                        let subschema = generateSchema(schema[i].value[j]);
                        updateConstSchemamapping(subschema, submapping[j])
                    }
                    let mapping = {...schemamapping[i].mapping};
                    mapping = {
                            ...mapping,
                            mapping: submapping
                          }
                    schemamapping[i] = {...schemamapping[i],
                                    mapping: mapping
                                   }
                }
            }
        }else{
            if(schemamapping[i].mapping.action=="const"){
                let mapping = {...schemamapping[i].mapping};
                mapping = {...mapping,
                            val: schema[i].value
                          }
                schemamapping[i] = {...schemamapping[i],
                                mapping: mapping
                               }
            }
        }
    }
}

export const transformvar = createAsyncThunk(
    "apptemplates/transformvar",
    async (payload, {dispatch, rejectWithValue, getState})=>{
        let updatedSchema = [...payload.schema];
        let results = [];
        for(let i=0; i<updatedSchema.length; i++){
            if(updatedSchema[i]?.key=="variable"){
                let varschema = updatedSchema[i];
                let appvariables = JSON.parse(JSON.stringify(getState().apptemplates.appvariables));
                let appvariablescopy = JSON.parse(JSON.stringify(getState().apptemplates.appvariables));
                let childschemamapping = [...appvariables[payload.childkey].schemamapping];
                updateConstSchemamapping([varschema], childschemamapping);
                appvariables[payload.childkey] = {
                                ...appvariables[payload.childkey],
                                schema: [varschema],
                                schemamapping: childschemamapping
                            }
                appvariablescopy[payload.childkey] = {
                                    ...appvariablescopy[payload.childkey],
                                    schema: [varschema],
                                    schemamapping: childschemamapping
                                }
                let templatepipelines = getState().apptemplates.templatepipelines;
                updateVar(
                            appvariablescopy, 
                            payload.childkey,
                            templatepipelines,
                            dispatch
                        )
                getReactivePipelines(
                                        payload.childkey,
                                        templatepipelines,
                                        "variable",
                                        dispatch
                                    );
                results.push({
                                "type": "variable",
                                appvariables: appvariablescopy
                             });
            }else if(updatedSchema[i]?.key=="inputs"){
                let varschema = updatedSchema[i];
                let appinputs = JSON.parse(JSON.stringify(getState().apptemplates.appinputs));
                appinputs[payload.childkey] = [...varschema.subschema];
                results.push({
                                "type": "input",
                                appinputs: appinputs,
                                inputs: [...varschema.subschema]
                            }) 
            }
        }
        return results;
    }
)

export const onChangeHandler = createAsyncThunk(
    "apptemplates/onchangehandler",
    async(payload, {dispatch, rejectWithValue, getState})=>{
        try{
            let val = payload.val;
            let index = payload.index;
            let key = payload.key;
            let appinputs = JSON.parse(JSON.stringify(getState().apptemplates.appinputs));
            let inputs = appinputs[key];
            if(inputs[index]?.type=="file"){
                let fileurl = URL.createObjectURL(payload.file)
                let output = {
                                "url": fileurl,
                                "name": val,
                                "size": payload.file.size,
                                "lastModified": payload.file.lastModified
                            }
                let subschema = [
                                    {
                                        "key": "url",
                                        "label": "URL",
                                        "value": fileurl,
                                        "subschemainputs":[],
                                        "type": "string"
                                    },
                                    {
                                        "key": "name",
                                        "label": "Name",
                                        "value": val,
                                        "subschema":[],
                                        "type": "string"
                                    },
                                    {
                                        "key": "size",
                                        "label": "Size",
                                        "value": payload.file.size,
                                        "subschema":[],
                                        "type": "string"
                                    },
                                    {
                                        "key": "lastModified",
                                        "label": "LastModified",
                                        "value": payload.file.lastModified,
                                        "subschema":[],
                                        "type": "string"
                                    }
                                ]
                inputs[index] = {...inputs[index],
                                 value: val,
                                 file: fileurl,
                                 subschema: subschema
                                }
            }else{
                inputs[index] = {...inputs[index],
                                 value: val
                                }
            }
            getReactivePipelines(
                                 key,
                                 getState().apptemplates.templatepipelines,
                                 "inputs",
                                 dispatch
                                )
            appinputs[key] = inputs
            return {
                    appinputs: appinputs,
                    inputs: inputs
                    }
        }catch(error){
            console.log(error);
        }
    }
)

const apptemplateSlice = createSlice({
    name: "apptemplates",
    initialState:{
        "templates": [],
        "templatevariables":{},
        "pipelineSubs":{
            "key": []
        },
        "routeparams":{},
        "templatepipelines":{},
        "activetemplatepipelines": [],
        "activevariableid": "",
        "appelements":{},
        "appvariables":{},
        "appclasses":{},
        "appvariablesdependency": {},
        "appinputs":{},
        "elements":[],
        "activepostion":[],
        "elementDragged":[],
        "activetemplatesettings": undefined,
        "elementInFocus":[],
        "elementActive":[],
        "inputs": [],
        "activetemplateid": "",
        "apptemplates": [],
        "prod": true,
        "templateloadstatus": {
            "": true
        },
        "currentpath": "",
        "mode": "",
        "templateroots":{

        }
    },
    reducers:{
        setElementDragged: (state, action)=>{
            state.elementDragged = action.payload;
        },
        resetactivetemplateid: (state, action)=>{
            state.activetemplateid = "";
            state.activevariableid = "";
        },
        resetelements: (state, action)=>{
            state.elements = [];
            state.appelements = {};
        },
        resetvariables: (state, action)=>{
            state.appvariables = {};
            state.templatevariables = {};
        },
        resetactivetemplatepipelines: (state, action)=>{
            state.activetemplatepipelines = [];
            state.templatepipelines = [];
        },
        setTemplateVariable: (state, action)=>{
            let templatevariableCopy = {...state.templatevariables};
            templatevariableCopy[action.payload.key] = action.payload.value;
            state.templatevariables = templatevariableCopy;
        },
        setElements: (state, action)=>{
            state.elements = action.payload;
            let appelementsCopy = {...state.appelements};
            appelementsCopy[state.activetemplateid] = action.payload;
            state.appelements = appelementsCopy;
        },
        setTemplateId: (state, action)=>{
            state.activetemplateid = action.payload;
        },
        transformvar: (state, action)=>{
            let appvariables = JSON.parse(JSON.stringify(state.appvariables));
            appvariables[action.payload.childkey] = {...appvariables[action.payload.childkey],
                                                     schema: action.payload.schema
                                                    }
            updateVar(appvariables, action.payload.childkey)
            state.appvariables = appvariables;
        },
        onChangeHandler: (state, action)=>{
            let val = action.payload.val;
            let index = action.payload.index;
            let key = action.payload.key;
            let appinputs = {...state.appinputs};
            let inputs = appinputs[key];
            if(inputs[index].type=="file"){
                let fileurl = URL.createObjectURL(action.payload.file)
                let output = {
                    "url": fileurl,
                    "name": val,
                    "size": action.payload.file.size,
                    "lastModified": action.payload.file.lastModified
                }
                let subschema = [
                    {
                        "key": "url",
                        "label": "URL",
                        "value": fileurl,
                        "subschema":[]
                    },
                    {
                        "key": "name",
                        "label": "Name",
                        "value": val,
                        "subschema":[]
                    },
                    {
                        "key": "size",
                        "label": "Size",
                        "value": action.payload.file.size,
                        "subschema":[]
                    },
                    {
                        "key": "lastModified",
                        "label": "LastModified",
                        "value": action.payload.file.lastModified,
                        "subschema":[]
                    }
                ]
                inputs[index] = {...inputs[index],
                                 value: val,
                                 file: fileurl,
                                 subschema: subschema
                                }
            }else{
                inputs[index] = {...inputs[index],
                    value: val
                   }
            }
            appinputs[key] = inputs
            state.appinputs = appinputs;
            state.inputs = inputs
        },
        setProd: (state, action)=>{
            state.prod = action.payload;
        },
        setCurrentPath: (state, action)=>{
            state.currentpath = action.payload;
        },
        setmode: (state, action)=>{
            state.mode = action.payload;
        },
        resetmode: (state, action)=>{
            state.mode = "";
        },
        resetapptemplates: (state, action)=>{
            state.apptemplates = []
        }
    },
    extraReducers: (builder)=>{
        builder.addCase(getAppPipelines.fulfilled, (state, action)=>{
            let resp = action.payload;
            if(resp!=undefined){
                let appPipelinesCopy = {...state.templatepipelines};
                appPipelinesCopy[resp.key] = resp.pipelines;
                state.templatepipelines = appPipelinesCopy;
            }
            
        })
        builder.addCase(getAppVariables.fulfilled, (state, action)=>{
            let templatevariable = action.payload.variable;
            let parentkey = action.payload.elmkey;
            let appvariablesCopy = {...state.appvariables};
            if(templatevariable.variable!=null&&templatevariable.variable!=undefined){
                
                let schema = [...templatevariable.variable.schema];
                let schemamapping = [...templatevariable.variable.schemamapping];
                if(parentkey!=""){
                    schemamapping = getElementPropsMapping(state.appelements, parentkey, action.payload.indexarr);
                }

                let depsarray = [];
                
                if(templatevariable.variable.depsarray!=undefined){
                    depsarray = [...templatevariable.variable.depsarray];
                }
                
                let parentvariable = {...state.appvariables[parentkey]};
                
                getValuedSchema(schema, schemamapping, parentvariable?.schema);
                
                // update parent deps array
                if(parentkey!=""){
                    parentvariable = adddepstovar(parentvariable, action.payload.key);
                    appvariablesCopy[parentkey] = parentvariable;
                }

                // get the parent variable
                appvariablesCopy[action.payload.key] = {
                                                         schema: schema,
                                                         schemamapping: schemamapping,
                                                         parent: parentkey,
                                                         depsarray: depsarray
                                                       }
                // update the dependencies
                updateVariableDeps(appvariablesCopy, action.payload.key);
                state.appvariables = appvariablesCopy;
                let templateloadstatus = {...state.templateloadstatus};
                templateloadstatus[action.payload.key] = true;
                state.templateloadstatus = templateloadstatus;
            }
        })
        builder.addCase(getAppElements.fulfilled, (state, action)=>{
            if(action.payload.source=="pipelines"){
                let appelementsCopy = state.appelements;
                if(appelementsCopy[action.payload.key]==undefined||appelementsCopy[action.payload.key]?.length==0){
                
                    let appelementsCopy = state.appelements;
                    appelementsCopy[action.payload.key] = action.payload.elements;
                    let inputs = [];
                    // load app inputs
                    loadAppInputs(action.payload.elements, inputs);
                    let appinputs = state.appinputs;
                    appinputs[action.payload.key] = inputs
                    state.appinputs = appinputs;
                    state.appelements = appelementsCopy;
                    
                }

            }else{
                let appelementsCopy = state.appelements;
                appelementsCopy[action.payload.key] = action.payload.elements;
                let inputs = [];
                // load app inputs
                loadAppInputs(action.payload.elements, inputs);
                let appinputs = state.appinputs;
                appinputs[action.payload.key] = inputs
                state.appinputs = appinputs;
                state.appelements = appelementsCopy;    
            }
        })
        builder.addCase(getAppTemplates.fulfilled, (state, action)=>{
            let templatelist = action.payload;
            let activetemplateindex = _.findIndex(templatelist,(t)=>{return t._id==state.activetemplateid});
            if(activetemplateindex>-1){
                templatelist.splice(activetemplateindex,1)
            }
            state.apptemplates = templatelist;
        })
        builder.addCase(getAppclasses.fulfilled, (state, action)=>{
            if(action.payload!=null){
                let appclassesCopy = {...state.appclasses};
                appclassesCopy[action.payload.key] = [...action.payload.classes] 
                state.appclasses = appclassesCopy;
            }
        })
        builder.addCase(transformvar.fulfilled, (state, action)=>{
            let results = action.payload;
            for(let i=0; i<results.length; i++){
                if(results[i].type=="input"){
                    state.inputs = results[i].inputs;
                    state.appinputs = results[i].appinputs;
                }else if(results[i].type=="variable"){
                    state.appvariables = results[i].appvariables
                }
            }
        })
        builder.addCase(onChangeHandler.fulfilled, (state, action)=>{
            state.appinputs = action.payload.appinputs;
            state.inputs = action.payload.inputs;
        })
        builder.addCase(getTemplateRoot.fulfilled, (state, action)=>{
            let templateid = action.payload.templateid;
            let root = action.payload.result;
            let rootelements = root.appelements;
            let rootclasses = root.appclasses;
            let rootPipelines = root.apppipelines;
            let rootElementKeys = Object.keys(rootelements);
            let appelementsCopy = {...state.appelements};
            let appinputs = {...state.appinputs};
            let appclassesCopy = {...state.appclasses};
            let appPipelinesCopy = {...state.templatepipelines};

            for(let i=0; i < rootElementKeys.length; i++){
                
                appelementsCopy[rootElementKeys[i]] = rootelements[rootElementKeys[i]];
                
                let inputs = [];
                
                // load app inputs
                loadAppInputs(
                                rootelements[rootElementKeys[i]], 
                                inputs
                             );
                appinputs[rootElementKeys[i]] = inputs
                
                // load styles
                let keyStyle = rootclasses[rootElementKeys[i]];
                if(keyStyle!=undefined){
                    appclassesCopy[rootElementKeys[i]] = keyStyle;
                }
                
                // load template pipelines
                let keyPiplines = rootPipelines[rootElementKeys[i]];
                if(keyPiplines!=undefined){
                    appPipelinesCopy[rootElementKeys[i]] = keyPiplines;
                }   
            }
            //copy the template load status
            let templateRootsCopy = {...state.templateroots};
            templateRootsCopy[templateid] = true;
            state.appelements = appelementsCopy;
            state.appinputs = appinputs;
            state.appclasses = appclassesCopy;
            state.templatepipelines = appPipelinesCopy;
            state.templateroots = templateRootsCopy;
        })
    }
})

export const selectActiveTemplatePipelines = (state) => state.apptemplates.activetemplatepipelines;

export const selectActiveElements = (state) => state.apptemplates.elements;

export const selectActiveTemplateSettings = (state) => state.apptemplates.activetemplatesettings;

export const selectAppTemplates = (state) => state.apptemplates.apptemplates;

export const selectActivetemplateid = (state) => state.apptemplates.activetemplateid;

export const selectTemplateroots = (state) => state.apptemplates.templateroots;

export const selectTemplateLoadStatus = createSelector(
    [state=> state.apptemplates.templateloadstatus, (state, templatekey)=> templatekey],
    (templateloadstatus, templatekey)=>{
        return templateloadstatus[templatekey];
    }
)

export const jsoncompare = (val1,val2)=>{
    if(JSON.stringify(val1)==JSON.stringify(val2)){
        return true
    }else{
        return false;
    }
}

export const selectAppElements = createSelector(
    [state => state.apptemplates.appelements, (state, key)=> key],
    (appelements, key)=>{
        let elements = appelements[key];
        if(elements==undefined){
            return []
        }else{
            return elements;
        }
    }
)

export const selectTemplateVariable = createSelector(
    [state=> state.apptemplates.templatevariables, (state, templateid)=> templateid],
    (templatevariables, templateid)=>{
        let variable;
        variable  = templatevariables[templateid];
        return variable;
    }
)

export const selectRouteParams = createSelector(
    [state=> state.apptemplates.routeparams, (state, templateid)=> templateid],
    (routeparams, templateid)=>{
        return routeparams[templateid];
    }
)

export const selectConditions = createSelector(
    [
        (state)=>{
            return state.apptemplates;
        },
        (state, conditions)=>{
            return conditions;
        },
        (state, variable,key)=>{
            return key
        },
        (state, variable, key, position)=>{
            return position;
        }
    ],
    (apptemplates, conditions, key, position)=>{
        let res = false;
        let appvariables = apptemplates.appvariables;
        let templatevariables = appvariables[key]?.schema;
        let elements = apptemplates.appelements[key];
        let loopvariables = generateLoopVaraibleP(elements, templatevariables, position)
        let appinputs = apptemplates.appinputs;
        let inputs = appinputs[key];
        for(let i=0; i< conditions.length; i++){
            let blockproceed = true;
            for(let j=0; j<conditions[i].length; j++){
                let valuetocomparemapping = {...conditions[i][j].valuetocompare};
                let valuetocompare;
                if(valuetocomparemapping.action=="const"){
                    valuetocompare = valuetocomparemapping.val;
                }
                if(valuetocomparemapping.action=="get"){
                    valuetocompare = getValfromPipe(
                                                        valuetocomparemapping.val,
                                                        templatevariables,
                                                        loopvariables,
                                                        inputs
                                                    );
                }
                let valuecomparetomapping = conditions[i][j].valuecompareto;
                let valuecompareto;
                if(valuecomparetomapping.action=="const"){
                    valuecompareto = valuecomparetomapping.val;
                }
                if(valuecomparetomapping.action=="get"){
                    valuecompareto = getValfromPipe(
                                                        valuecomparetomapping.val, 
                                                        templatevariables,
                                                        loopvariables,
                                                        inputs
                                                    );
                }
                if(typeof valuetocompare=="boolean"){
                    if(valuecompareto=="true"){
                        valuecompareto = true
                    }else if(valuecompareto=="false"){
                        valuecompareto = false
                    }else{
                        valuecompareto = undefined;
                    }
                }
                let resp = executeElementConditions(conditions[i][j].condition, valuetocompare, valuecompareto);
                blockproceed = blockproceed&&resp;

            }
            res = res||blockproceed;
        }
        return res;
    }
)

export const selectElementStyles = createSelector(
    [
        (state) => state.apptemplates,
        (apptemplates, styles) => styles,
        (apptemplates, styles, key) => key,
        (apptemplates, styles, key, position) => position 
    ],
    (apptemplates, styles, key, position)=>{
        if(styles==undefined||key==undefined||position==undefined){
            return {}
        }else{
            let computedStyles = {};
            let appvariables = apptemplates.appvariables;
            let templatevariables = appvariables[key]?.schema;
            for(let i=0; i<styles.length; i++){
                if(styles[i].blocktype=="block"){
                    let stylename = camelCaseHypens(styles[i].name);
                    let stylevalue = generateStyleValue(styles[i]);
                    if(stylevalue!=""&&stylevalue!=undefined&&stylevalue!=null){
                        computedStyles[stylename] = stylevalue;
                    }
                }else if(styles[i].blocktype=="conditional"){
                    let conditions = styles[i].conditions;
                    let res = false;
                    for(let j=0; j<conditions.length; j++){
                        let blockproceed = true;
                        for(let k=0; k<conditions[j].length; k++){
                            let valuetocomparemapping = {...conditions[j][k].valuetocompare};
                            let valuetocompare;
                            if(valuetocomparemapping.action=="const"){
                                valuetocompare = valuetocomparemapping.val;
                            }
                            if(valuetocomparemapping.action=="get"){
                                valuetocompare = getValfromPipe(valuetocomparemapping.val,templatevariables);
                            }
                            let valuecomparetomapping = conditions[j][k].valuecompareto;
                            let valuecompareto;
                            if(valuecomparetomapping.action=="const"){
                                valuecompareto = valuecomparetomapping.val;
                            }
                            if(valuecomparetomapping.action=="get"){
                                valuecompareto = getValfromPipe(valuecomparetomapping.val, templatevariables);
                            }
                            let resp = executeElementConditions(conditions[j][k].condition, valuetocompare, valuecompareto);
                            blockproceed = blockproceed&&resp;
            
                        }
                        res = res||blockproceed;
                    }
                    if(res){
                        let stylename = camelCaseHypens(styles[i].name);
                        let stylevalue = generateStyleValue(styles[i]);
                        if(stylevalue!=""&&stylevalue!=undefined&&stylevalue!=null){
                            computedStyles[stylename] = stylevalue;
                        }
                    }
                }
            }
            return computedStyles;
        }
    }
)

export const selectProps = createSelector(
    [
        (state) => state.apptemplates,
        (apptemplates, variable) => variable,
        (apptemplates, variable, key)=> key,
        (apptemplates, variable, key, position) => position
    ],
    (apptemplates, variable, key, position)=>{
        let objectval = {}
        let sc = variable.schema;
        let sm = variable.schemamapping;
        let appvariables = apptemplates.appvariables;
        let templatevariables = appvariables[key]?.schema;
        let elements = apptemplates.appelements[key];
        let loopvariables = generateLoopVaraibleP(elements, templatevariables, position)
        let appinputs = apptemplates.appinputs;
        let inputs = appinputs[key];
        objectval = getAppObjectVal(
                                    sc, 
                                    sm, 
                                    templatevariables, 
                                    loopvariables, 
                                    inputs, 
                                    [],
                                    objectval,
                                    apptemplates.prod,
                                    apptemplates.currentpath
                                    );
        return objectval;

    }
)

export const selectInputVariables = (templateid)=>{
    return (state)=>{
        let apptemplates = state.apptemplates;
        let templateinputs = JSON.parse(JSON.stringify(apptemplates.appinputs[templateid]));
        let inputvariable = [{
            "key": "inputs",
            "label": "Inputs",
            "type": "object",
            "subschema": [...templateinputs]
        }];
        return inputvariable;

    }
}

export const selectInputVal = (state, key, index)=> state.apptemplates.appinputs[key]?.at(index)?.value;

const insertLoopVariable = (loopvariables, currentloopvariable)=>{
    if(loopvariables.length==0){
        loopvariables = currentloopvariable
    }else{
        if(loopvariables[1].subschema.length==0){
            loopvariables[1] = {...loopvariables[1],
                                subschema: currentloopvariable
                                }
        }else{
            insertLoopVariable(loopvariables[1].subschema, currentloopvariable)
        }
    }
}

const generateLoopVaraible = (
    loopvariable, 
    loopvariablemapping, 
    templatevariables,
    env,
    currentpath
    )=>{
    let loopvar = getAppObjectVal(
                                    loopvariable, 
                                    loopvariablemapping, 
                                    templatevariables?.schema,
                                    [], 
                                    [],
                                    [], 
                                    {},
                                    env,
                                    currentpath
                                );
    if(loopvar.loopvariable.length>0){
        let firstelement = loopvar.loopvariable[0];
        let loopvarschema = [];
        let currentindex = {
                            "currentindex": firstelement
                           }
        generateSchema(currentindex, loopvarschema);
        return loopvarschema;
    }else{
        return [];
    }
}

// todo: check the algo
const getConcernedElementIndex = (prefix_done, current_position_prefix)=>{
    let prefixparts = prefix_done.split("__");
    let currentparts = current_position_prefix.split("__");
    if(prefixparts.length>currentparts.length){
        throw new Error("Unsupported index found");
    }
    return currentparts[(currentparts.length-1)];
}

export const generateLoopVaraibleP = (elements, templatevariables, position, env, currentpath)=>{
    let loopvariables = [];
    let prefix_done = "";
    let secondPointer = elements;
    if(position!=undefined&&elements!=undefined&&templatevariables!=undefined){
        for(let i=0; i<position.length; i++){
            if(secondPointer[position[i]].type=="loop"){
                if(position.length>(i+1)&&position[i+1].toString().startsWith("ci__")){
                    let loopvariable = secondPointer[position[i]].loopvariable;
                    let loopvariablemapping = secondPointer[position[i]].loopvariablemapping;
                    let loopvar = getAppObjectVal(
                                                    loopvariable, 
                                                    loopvariablemapping, 
                                                    templatevariables, 
                                                    loopvariables, 
                                                    [], 
                                                    [],
                                                    {},
                                                    env,
                                                    currentpath
                                                    );
                    let currentindex = getConcernedElementIndex(prefix_done, position[i+1]);
                    let concerned_element = loopvar.loopvariable[currentindex];
                    let loopvarschema = [];
                    let current_index = {
                        "currentindex": concerned_element
                    }
                    generateSchema(current_index, loopvarschema);
                    let currentloopvariable = [
                        loopvarschema[0],
                        {
                            "key": "loopvariable",
                            "label": "Loop Variable",
                            "type": "object",
                            "subschema":[]
                        }
                    ]
                    if(loopvariables.length==0){
                        loopvariables = [...currentloopvariable];
                    }else{
                        insertLoopVariable(loopvariables, currentloopvariable);
                    }
                    secondPointer = secondPointer[position[i]].childs;
                    i = i+1;
                }else{
                    let loopvariable = secondPointer[position[i]].loopvariable;
                    let loopvariablemapping = secondPointer[position[i]].loopvariablemapping;
                    let loopvar = getAppObjectVal(
                                                    loopvariable, 
                                                    loopvariablemapping, 
                                                    templatevariables, 
                                                    loopvariables, 
                                                    [],
                                                    [], 
                                                    {},
                                                    env,
                                                    currentpath
                                                    );
                    let currentindex = 0;
                    let concerned_element = loopvar.loopvariable[currentindex];
                    let loopvarschema = [];
                    let current_index = {
                        "currentindex": concerned_element
                    }
                    generateSchema(current_index, loopvarschema);
                    let currentloopvariable = [
                        loopvarschema[0],
                        {
                            "key": "loopvariable",
                            "label": "Loop Variable",
                            "type": "object",
                            "subschema":[]
                        }
                    ]
                    if(loopvariables.length==0){
                        loopvariables = [...currentloopvariable];
                    }else{
                        insertLoopVariable(loopvariables, currentloopvariable);
                    }
                        
                    
                }
            }
            // to handle loop elements
            if(i<position.length&&position[i].toString().startsWith("ci__")==false){
                secondPointer = secondPointer[position[i]].childs;
            }
            
        }
    }
    return loopvariables;
}

export const selectLoopVariables = createSelector(
    [
        (state)=> state.apptemplates, 
        (state,templateid)=> templateid,
        (state,templateid,indexarr) => indexarr
    ],
    (apptemplates, templateid, indexarr)=>{
        if(templateid==undefined||indexarr==undefined){
            return []
        }
        let appvariables = apptemplates.appvariables[templateid];
        let elements = apptemplates.appelements[templateid];
        let loopvariables = [];
        for(let i=0; i< indexarr.length; i++){
            if(elements[indexarr[i]].type=="loop"){
                let loopv = generateLoopVaraible(
                                                    elements[indexarr[i]].loopvariable, 
                                                    elements[indexarr[i]].loopvariablemapping, 
                                                    appvariables,
                                                    apptemplates.env,
                                                    apptemplates.currentpath
                                                    )
                let currentloopvariable = [
                    loopv,
                    {
                        "key": "loopvariable",
                        "label": "Loop Variable",
                        "type": Object,
                        "subschema":[]
                    }
                ]
                if(loopvariables.length>0){
                    let loopvarindex = _.findIndex(loopvariables, (lv)=>{return lv.key=="loopvariable"});
                    loopvariables[loopvarindex] = {...loopvariables[loopvarindex],
                                                   subschema: currentloopvariable
                                                  }
                }else{
                    loopvariables = currentloopvariable;
                }
            }
        }

        return loopvariables;

    }
)

export const selectLoopVariableV1 = (templateid, indexarr)=>{
    return (state)=>{
        if(templateid==undefined||indexarr==undefined){
            return []
        }
        let apptemplates = state.apptemplates;
        let appvariables = apptemplates.appvariables[templateid];
        let elements = apptemplates.appelements[templateid];
        let loopvariables = [];
        for(let i=0; i< indexarr.length; i++){
            if(elements[indexarr[i]].type=="loop"){
                let loopv = generateLoopVaraible(
                                                    elements[indexarr[i]].loopvariable, 
                                                    elements[indexarr[i]].loopvariablemapping, 
                                                    appvariables,
                                                    apptemplates.env,
                                                    apptemplates.currentpath
                                                    )
                let currentloopvariable = [
                    loopv[0],
                    {
                        "key": "loopvariable",
                        "label": "Loop Variable",
                        "type": "object",
                        "subschema":[]
                    }
                ]
                if(loopvariables.length==0){
                    loopvariables = [...currentloopvariable];
                }else{
                    insertLoopVariable(loopvariables, currentloopvariable);
                }
                
            }
            elements = elements[indexarr[i]].childs;
        }
        return loopvariables;
    }

}

export const selectInnerHtml = createSelector(
    [
        (state)=>{
            return state.apptemplates
        },
        (state, variable)=> variable,
        (state, variable, routeparams)=> routeparams, 
        (state, variable, routeparams, key)=> key,
        (state, variable, routeparams, key, position) => position
    ],
    (apptemplates, variable, routeparams, key, position)=>{
        let objectval = {}
        let sc = variable.schema;
        let sm = variable.schemamapping;
        let appvariables = apptemplates.appvariables;
        let templatevariables = appvariables[variable.key]?.schema;
        let elements = apptemplates.appelements[variable.key];
        let loopvariables = generateLoopVaraibleP(elements, templatevariables, position)
        let appinputs = apptemplates.appinputs;
        let inputs = appinputs[variable.key];
        let inputvariable = [{
            "key": "inputs",
            "label": "inputs",
            "type": "object",
            "subschema": inputs
        }]
        if(apptemplates.prod==false&&apptemplates.activetemplateid==key){
            if(apptemplates.routeparams[key]!=undefined){
                routeparams = JSON.parse(JSON.stringify(apptemplates.routeparams[key]));
                routeparams = routeparams.schema;
            }else{
                routeparams = [];
            }
            
        }else{
            let rp = [{
                "key": "routeparams",
                "label": "Routeparams",
                "type": "object",
                "subschema":[]
            }]
            let subschema = [];
            generateSchema(routeparams, subschema);
            rp[0] = {...rp[0],
                     subschema: subschema
                    }
            routeparams = rp;
        }
        objectval = getAppObjectVal(
                                        sc,
                                        sm, 
                                        templatevariables, 
                                        loopvariables, 
                                        inputvariable, 
                                        routeparams,
                                        objectval,
                                        apptemplates.env,
                                        apptemplates.currentpath
                                        );
        if(objectval=={}){
            return undefined
        }else{
            return objectval.value;
        }
        
    }
)

export const selectSeoProps = createSelector(
    [
        (state)=>{
            return state.apptemplates
        },
        (state, variable)=> variable,
        (state, variable, routeparams)=> routeparams, 
        (state, variable, routeparams, key)=> key,
        (state, variable, routeparams, key, position) => position,
        (state, variable, routeparams, key, position, result)=> result
    ],
    (apptemplates, variable, routeparams, key, position, result)=>{
        let sc = variable.schema;
        let sm = variable.schemamapping;
        let appvariables = apptemplates.appvariables;
        let templatevariables = appvariables[variable.key]?.schema;
        let elements = apptemplates.appelements[variable.key];
        let loopvariables = generateLoopVaraibleP(elements, templatevariables, position)
        let appinputs = apptemplates.appinputs;
        let inputs = appinputs[variable.key];
        let inputvariable = [{
                                "key": "inputs",
                                "label": "inputs",
                                "type": "object",
                                "subschema": inputs
                            }]
        if(apptemplates.prod==false&&apptemplates.activetemplateid==key){
            if(apptemplates.routeparams[key]!=undefined){
                routeparams = JSON.parse(JSON.stringify(apptemplates.routeparams[key]));
                routeparams = routeparams.schema;
            }else{
                routeparams = [];
            }
            
        }else{
            let rp = [{
                "key": "routeparams",
                "label": "Routeparams",
                "type": "object",
                "subschema":[]
            }]
            let subschema = [];
            generateSchema(routeparams, subschema);
            rp[0] = {...rp[0],
                     subschema: subschema
                    }
            routeparams = rp;
        }
        result = getAppObjectVal(
                                        sc,
                                        sm, 
                                        templatevariables, 
                                        loopvariables, 
                                        inputvariable, 
                                        routeparams,
                                        result,
                                        apptemplates.env,
                                        apptemplates.currentpath
                                        );
        return result;
        
    }
)

export const selectLoopVariableValue = createSelector(
    [
        (state) => state.apptemplates,
        (state, variable) => variable,
        (state, variable, key) => key,
        (state, variable, key, position) => position
    ],
    (apptemplates, variable, key, position)=>{
        let objectval = {};
        let sc = variable.sc;
        let sm = variable.sm;
        let appvariables = apptemplates.appvariables;
        let templatevariable = appvariables[key]?.schema;
        let elements = apptemplates.appelements[key];
        let loopvariable = generateLoopVaraibleP(elements, templatevariable, position);
        objectval = getAppObjectVal(
                                    sc, 
                                    sm, 
                                    templatevariable, 
                                    loopvariable,
                                    [], 
                                    [],
                                    objectval,
                                    apptemplates.env,
                                    apptemplates.currentpath
                                    );
        if(objectval==undefined&&objectval=={}&&objectval.loopvariable==undefined){
            return []
        }else{
            return objectval.loopvariable;
        }
    }
)

export const selectActiveElement = (position)=>{
    return (state)=>{
        let elements = state.apptemplates.elements;
        let element;
        let secondPointer = elements;
        for(let i=0; i < position.length; i++){
            if(i==(position.length-1)){
                element = secondPointer[position[i]];
            }else{
                secondPointer = secondPointer[position[i]].childs;
            }
        }
        return element;
    }
}

export const selectAppPipelines = createSelector(
    [
        (state) => state.apptemplates,
        (state,key) => key,
        (state, key, res) => res 
    ],
    (apptemplates, key, res)=>{
        let apppipelines = apptemplates.templatepipelines;
        if(apppipelines!=undefined){
            res = apppipelines[key];
            return res;
        }
    }
)

export const selectAppVariables = createSelector(
    [
        (state) => state.apptemplates,
        (state,key) => key
    ],
    (apptemplates, key)=>{
        let appvariables = apptemplates.appvariables;
        if(appvariables!=undefined){
            return appvariables[key]
        }
        
    }
)

export const selectElementClasses = createSelector(
    [
        (state) => state.apptemplates,
        (apptemplates, scusers)=> scusers,
        (apptemplates, scusers, scsystems)=> scsystems,
        (apptemplates, scusers, scsystems, key) => key,
        (apptemplates, scusers, scsystems, key, position) => position
    ],
    (apptemplates, scusers, scsystems, key, position)=>{
            if(scusers==undefined||scsystems==undefined||key==undefined||position==undefined){
                return ""
            }else{
                let clsString = "";
                let appvariables = apptemplates.appvariables;
                let templatevariables = appvariables[key]?.schema
                let clstoapply = [...scusers, ...scsystems];
                for(let i=0; i<clstoapply.length; i++){
                    let clsname = clstoapply[i].name;
                    if(clstoapply[i].blocktype=="conditional"){
                        let conditions = clstoapply[i].conditions;
                        let res = false;
                        for(let j=0; j<conditions.length; j++){
                            let blockproceed = true;
                            for(let k=0; k<conditions[j].length; k++){
                                let valuetocomparemapping = {...conditions[j][k].valuetocompare};
                                let valuetocompare;
                                if(valuetocomparemapping.action=="const"){
                                    valuetocompare = valuetocomparemapping.val;
                                }
                                if(valuetocomparemapping.action=="get"){
                                    valuetocompare = getValfromPipe(valuetocomparemapping.val,templatevariables);
                                }
                                let valuecomparetomapping = conditions[j][k].valuecompareto;
                                let valuecompareto;
                                if(valuecomparetomapping.action=="const"){
                                    valuecompareto = valuecomparetomapping.val;
                                }
                                if(valuecomparetomapping.action=="get"){
                                    valuecompareto = getValfromPipe(valuecomparetomapping.val, templatevariables);
                                }
                                // required to parse const inputs from browser
                                if(typeof valuetocompare=="boolean"){
                                    if(valuecompareto=="true"){
                                        valuecompareto = true
                                    }else if(valuecompareto=="false"){
                                        valuecompareto = false
                                    }
                                }
                                let resp = executeElementConditions(conditions[j][k].condition, valuetocompare, valuecompareto);
                                blockproceed = blockproceed&&resp;
                
                            }
                            res = res||blockproceed;
                        }
                        if(res){
                            if(clsString==""){
                                clsString = clsString+clsname;
                            }else{
                                clsString = clsString+" "+clsname;
                            }
                            
                        }
                    }else{
                        if(clsString==""){
                            clsString = clsString+clsname;
                        }else{
                            clsString = clsString+" "+clsname;
                        }
                    }
                }
                return clsString;
            }
    }
)

export const selectAppClasses = (state) => state.apptemplates.appclasses;

export const { 
                setElementDragged,
                handleElementDrop,
                setElement,
                setElements,
                setTemplateVariable,
                setTemplateId,
                resetelements,
                resetvariables,
                resetactivetemplateid,
                updateElementProps,
                deleteElement,
                setInputType,
                setProd,
                setCurrentPath,
                setmode,
                resetmode,
                resetapptemplates
            } = apptemplateSlice.actions;

export default apptemplateSlice.reducer;