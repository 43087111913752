import { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAppClasses, selectAppTemplates } from "../../Services/apptemplates/apptemplates.slice";
import { generateStyleValue } from "./utils";
import { style } from "@mui/system";

const StyleSheet = ({

})=>{

    const [appStyles, setAppStyles] = useState({});

    const ref = useRef(null);

    const remotestyles = useSelector(selectAppClasses);

    const getSelector = (name, state)=>{
        if(state=="default"){
            return "."+name
        }else{
            return "."+name+":"+state
        }
    }

    const getMediaConditions = (mediafeatures)=>{
        let condition = "";
        for(let i=0; i<mediafeatures.length; i++){
            let blockcondition = "(";
            for(let j=0; j<mediafeatures[i].length; j++){
                let conditioname = mediafeatures[i][j].name;
                let conditionvalue = generateStyleValue(mediafeatures[i][j]);
                let conditionrule = ""
                if(conditioname!=""&&conditioname!=undefined&&conditionvalue!=""&&conditionvalue!=undefined){
                    conditionrule = ""+conditioname+":"+conditionvalue;
                }
                if(conditionvalue!=""){
                    if(blockcondition=="("){
                        blockcondition = blockcondition+"("+conditionrule+")";
                    }else{
                        blockcondition = blockcondition+" and "+"("+conditionrule+")";
                    }
                }
            }
            if(blockcondition!="("){
                blockcondition = blockcondition+")";
                if(condition!=""){
                    condition = condition+ " or "+blockcondition;
                }else{
                    condition = condition+blockcondition;
                }
            }
        }
        return condition;
    }

    const getMediaRule = (style)=>{
        let  rule = "";
        let mediacondition = getMediaConditions(style.mediafeatures);
        let cssSelector = "";
        if(mediacondition!=""){
            rule = "@media "+mediacondition+"{ "
            let cssrule = "";
            cssSelector = getSelector(style.name, "default");
            cssrule = cssrule+cssSelector+"{";
            let valid = false;
            for(let i=0; i < style.styles.length; i++){
                cssrule = cssrule+"";
                let stylename = style.styles[i].name;
                let value = generateStyleValue(style.styles[i]);
                if(style.styles[i].name!=""&&style.styles[i].name!=undefined&&value!=""&&value!=undefined){
                    let stylerule = ""+stylename+": "+value+";"
                    cssrule = cssrule+stylerule;
                    valid = true;
                }
            }
            if(valid){
                cssrule = cssrule+"}";
            }
            if(cssrule!=""){
                rule = rule+cssrule+"}";
            }
        }
        
        return {
            selector: mediacondition,
            rule: rule,
            cssSelector: cssSelector
        };
    }

    const getRule = (style)=>{
        let rule = "";
        let selector = getSelector(style.name, style.state);
        rule = rule+selector+"{";
        let valid = false;
        let cssrule = "";
        for(let i=0; i< style.styles.length; i++){
            rule = rule+"";
            cssrule = cssrule+"";
            let stylename = style.styles[i].name;
            let value = generateStyleValue(style.styles[i]);
            if(style.styles[i].name!=""&&style.styles[i].name!=undefined&&value!=""&&value!=undefined){
                let stylerule = ""+stylename+": "+value+";"
                rule = rule+stylerule;
                cssrule = cssrule+stylerule;
                valid = true;
            }
        }
        if(valid){
            rule = rule+"}";
        }else{
            rule = ""
        }
        return {
                 selector: selector,
                 rule:rule,
                 cssrule: cssrule
                };
    }

    const findRuleIndex = (selector, rules)=>{
        let ruleindex = -1;
        for(let i=0; i< rules.length; i++){
            if(rules[i].selectorText!=undefined){
                let selectorText = rules[i].selectorText.replace(" ", "");
                if(selectorText==selector){
                    ruleindex = i;
                    break
                }
            }
        }
        return ruleindex;
    
    }

    const findMediaRuleIndex = (selector, cssSelector, rules)=>{
        let ruleindex = -1;
        for(let i=0; i< rules.length; i++){
            if(rules[i].conditionText!=undefined){
                let conditionText = rules[i].conditionText.replace(" ","");
                if(conditionText==selector){
                    let cssselector = rules[i].cssRules[0].selectorText;
                    cssselector = cssselector.replace(" ", "");
                    if(cssselector==cssSelector){
                        ruleindex = i;
                    }
                    break
                }
            }
        }
        return ruleindex;
    }

    const findMediaStyleIndex = (selector, cssselector,styles)=>{
        let ruleindex = -1;
        selector = selector.replace(" ", "");
        cssselector = cssselector.replace(" ", "");
        for(let i=0; i < styles.length; i++){
            if(styles[i].selector==selector&&styles[i].cssselector==cssselector){
                ruleindex = i;
                break;
            }
        }
        return ruleindex;
    }

    const findStyleIndex = (selector, activeselectors)=>{
        let styleindex = -1;
        selector = selector.replace(" ", "");
        for(let i=0; i<activeselectors.length; i++){
            if(activeselectors[i]==selector){
                styleindex = i;
                break;
            }
        }
        return styleindex;
    }

    const reconcileClassesV2 = (remotestyles)=>{
        let remotestylesCopy = {...remotestyles};
        let sheet = ref.current.sheet;
        let rulelist = sheet.cssRules;
        let templates = Object.keys(remotestylesCopy);
        
        //flatten the stylelist
        let stylerules = [];
        for(let i=0; i<templates.length; i++){
            let tstyles = remotestylesCopy[templates[i]];
            for(let j=0; j<tstyles.length; j++){
                stylerules.push(tstyles[j])
            }
        }

        let activeselectors = [];
        let activeconditions = [];

        let j=0;
        for(let i=0; i<stylerules.length; i++){
            if(stylerules[i].ruletype=="css"||stylerules[i].ruletype==undefined){
                let {rule, selector, cssrule} = getRule(stylerules[i]);
                if(selector!==""&&rule!=""){
                    activeselectors.push(selector);
                    let clsindex = findRuleIndex(selector, rulelist);
                    if(clsindex==-1){
                        if(rule!=""){
                            sheet.insertRule(rule, rulelist.length);
                        }
                    }else{
                        let csstext = rulelist[clsindex].cssText;
                        csstext = csstext.replace(" ","");
                        csstext = csstext.replace("\n","");
                        if(csstext!=rule){
                            sheet.deleteRule(clsindex);
                            if(rule!=""){
                                sheet.insertRule(rule, rulelist.length);
                            }
                        }
                    }
                    j = j+1;
                }
            }
            
            if(stylerules[i].ruletype=="media"){
                let {selector, rule, cssSelector} = getMediaRule(stylerules[i]);
                if(selector!=""&&rule!=""&&cssSelector!=""){
                    activeconditions.push({
                        "selector": selector,
                        "cssselector": cssSelector
                    });
                    let clsindex = findMediaRuleIndex(
                                                        selector, 
                                                        cssSelector, 
                                                        rulelist
                                                    );
                    if(clsindex==-1){
                        if(rule!=""){
                            sheet.insertRule(rule, rulelist.length);
                        }
                    }else{
                        let csstext = rulelist[clsindex].cssText;
                        csstext = csstext.replace(" ","");
                        csstext = csstext.replace("\n","");
                        if(csstext!=rule){
                            sheet.deleteRule(clsindex);
                            if(rule!=""){
                                sheet.insertRule(rule, rulelist.length);
                            }
                        }
                    }
                    j = j+1
                }
            }
        }
        
        sheet = ref.current.sheet;
        rulelist = sheet.cssRules;
        let rulelistCopy = [];
        for(let i=0; i<rulelist.length; i++){
            rulelistCopy.push(rulelist[i]);
        }
        let offset = 0;
        for(let i=0; i<rulelistCopy.length; i++){
            if(CSSStyleRule.isPrototypeOf(rulelist[i])){
                let selector = rulelistCopy[i].selectorText;
                let styleindex = findStyleIndex(selector, activeselectors);
                if(styleindex==-1){
                    sheet.deleteRule(i-offset);
                    offset = offset+1;
                }
            }
            if(CSSMediaRule.isPrototypeOf(rulelist[i])){
                let conditiontext = rulelistCopy[i].conditionText;
                let selectortext = rulelistCopy[i].cssRules[0].selectorText;
                let styleindex = findMediaStyleIndex(
                                                        conditiontext, 
                                                        selectortext,
                                                        activeconditions
                                                    );
                if(styleindex==-1){
                    sheet.deleteRule(i-offset);
                    offset = offset+1;
                }
            }   
        }
    }

    useEffect(()=>{
        if(remotestyles!=undefined){
            let remotestylesCopy = {...remotestyles};
            reconcileClassesV2(remotestylesCopy);
        }
        
    },[remotestyles])

    return (
        <style 
            ref = {ref}
            id="toiler-sheet"
        >
        </style>
    )
}

export default StyleSheet;
